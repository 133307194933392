import React, { useEffect, useState } from 'react';
import { Transition } from '@headlessui/react';
import Typing from 'react-typing-animation';

export default function TypingAnimation(props) {

    const [isActive, setActive] = useState(false);

    useEffect(() => {

        setActive(props.active);

    }, [props.active]);

    return(
        <Transition
            show={isActive}
            enter="transform transition duration-[400ms]"
            enterFrom="opacity-0 scale-50"
            enterTo="opacity-100 scale-100"
            leave="transform duration-200 transition ease-in-out"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
        >
            {isActive ? <Typing cursorClassName={props.cursorClassName} speed={props.speed} >{props.children}</Typing> : <></>}
        </Transition>
    );
}

TypingAnimation.defaultProps = {
    cursorClassName: 'cursor',
    active: false,
    speed: 50
};