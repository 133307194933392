import React, { useCallback, useEffect, useState } from 'react';

import LinkTransition from './linkTransition';
import TypingAnimation from '../components/typing';

import IconArrow from '../assets/icoArrowRightBlack.inline.svg';

const ContactBlock = (props) => {

    const [text, setText] = useState('');
    const [isShowing, setIsShowing] = useState(false);

    useEffect(() => {

        if (props?.weather?.main) {

            const weather = props.weather.main;

            const today = new Date();
            const curHr = today.getHours();

            let temp = '';
            let greeting = '';

            let tempC = parseInt(weather.temp);

            if (tempC < 15) {

                temp = 'friozão';

            } else if (tempC > 15 && tempC < 20) {

                temp = 'friozinho';

            } else if (tempC > 20 && tempC < 25) {

                temp = 'tempo gostosinho';

            } else if (tempC > 25 && tempC < 30) {

                temp = 'calorzinho';

            } else {

                temp = 'calorzão';
            }

            if (curHr < 12) {

                greeting = 'manhã';

            } else if (curHr < 18) {

                greeting = 'tarde';

            } else {

                greeting = 'noite';

            }

            setText(`Que ${temp} nessa ${greeting} eim`);

        } else {

            setText('Construção, renovação ou inovação');

        }

    }, [props.weather]);

    const handleScroll = useCallback(() => {

        const position = window.pageYOffset;

        const body = document.body;
        const html = document.documentElement;

        const height = Math.max(body.scrollHeight, body.offsetHeight,
            html.clientHeight, html.scrollHeight, html.offsetHeight);

        if ((position > (height * 0.7)) || !props.typing) {
            setIsShowing(true);
        }

    }, [props.typing]);

    useEffect(() => {

        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };

    }, [handleScroll]);

    return (

        <div className="container mx-auto">

            <div className="block md:flex py-24 md:py-40 border-t border-b">

                <div className="flex-none flex items-center w-full md:w-3/4">
                    <h2 className="text-6xl md:text-9xl text-yellow font-semibold md:pr-10">
                        <TypingAnimation cursorClassName="cursor-yellow" active={isShowing} speed={110} >
                            {text}
                        </TypingAnimation>
                    </h2>   
                </div>

                <div className="flex flex-col items-center justify-end">

                    <p className="text-xl font-regular my-10 md:mt-0 mt:mb-20">
                        Do que sua marca precisa hoje?
                    </p>

                    <LinkTransition
                        to="/contato"
                        className="contact-block-link flex align-center justify-between border-yellow border bg-yellow w-full"
                    >
                        <span className="contact-block-link-text text-center text-black w-full p-4 md:pl-6">
                            Vamos conversar?
                        </span>
                        <span className="mx-4 my-auto">
                            <IconArrow />
                        </span>
                    </LinkTransition>

                </div>
            </div>
        </div>
    );
};

export default ContactBlock;
